.get-in-touch {
    margin: 10rem auto;
    background-color: rgba(192, 159, 128, .25);
    box-shadow: 0px 8px 31px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
}

.get-in-touch h2, h4 {
    font-weight: var(--font-weight-bold);
}

.connect-with-us .socials {
    width: auto;
}

.social-img {
    width: 2rem;
}

@media screen and (max-width: 1000px) {
    .get-in-touch {
        margin: 3rem auto;
    }
    .connect-with-us {
        display: none!important;
    }
    .col-md-7 {
        width: 100%;
    }
}