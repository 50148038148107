.history {
    padding-top: 14rem;
}

.history-content {
    padding-right: 10rem;
}

.history h1 {
    font-weight: var(--font-weight-bold);
}

.history-paragraph {
    padding-top: 2rem;
}

.people {
    padding-top: 8rem;
}

.people h5 {
    font-weight: var(--font-weight-bold);
}

.people-content {
    padding-top: 4rem;
    padding-bottom: 6rem;
    align-items: center;
}

.people-image {
    display: flex;
    justify-content: center;
}

.mobile {
    display: none;
}

@media screen and (max-width: 780px) {
    .desktop {
        display: none;
    }
    
    .mobile {
        display: block;
    }

    .history {
        padding-top: 12rem;
    }

    .people {
        padding-top: 5rem;
    }

    .owner-img {
        max-width: 20rem;
        margin: auto;
    }

    .people-content {
        padding-top: 2rem;
    }
}
