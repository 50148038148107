.contact-form h5 {
    font-size: 1.125rem;
}
.contact-form h6 {
    padding: 0.25rem 1.5rem;
    font-family: var(--font-family-primary);
    font-size: 1.5rem;
    margin-bottom: 0;
}

.form-control {
    font-family: var(--font-family-secondary);
    background-color: var(--color-light);
    border-color: var(--color-gray);
}

.form-control::placeholder {
    font-family: var(--font-family-secondary);
}

.form-control:focus {
    background-color: var(--color-light);
    border-color: var(--color-maroon);
    box-shadow: 0 0 0 0.25rem rgba(56, 29, 34, 0.4); 
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--color-light) inset !important;
}

.form-label {
    margin-bottom: none;
}

.submit-btn {
    margin-top: 2rem;
    display: block;
    margin-left: auto;
    background-color: var(--color-maroon);
    border-color: var(--color-dark);
}

.submit-btn:hover {
    background-color: var(--color-dark);
    border-color: var(--color-dark);
}

/* honey-pot */
.not-today {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}