.visit {
    padding: 20rem 0 12rem 0;
}

.visit h2 {
    font-weight: 600;
}

.info {
    padding-right: 12dvw;
}

.learn-btn {
    margin-left: 2px;
}

.learn-btn h4 {
  font-family: var(--font-family-primary);
  font-size: 1.75rem;
  font-weight: 500;
}

.learn-btn .btn {
    --bs-btn-color: var(--color-dark);
    --bs-btn-border-color: var(--color-dark);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--color-dark);
    --bs-btn-hover-border-color: var(--color-dark);
}

@media screen and (max-width: 1400px) {
    .maine {
        padding-top: 3rem;
    }
}

@media screen and (max-width: 1000px) {
    .visit {
        padding: 20rem 0 8rem 0;
    }

    .maine {
        padding-top: 8rem;
    }

    .info p {
        font-size: small;
    }

    .learn-btn h4 {
        font-size: larger;
      }
      
}

@media screen and (max-width: 767px) {
    .maine {
        display: none;
        padding-top: 4rem;
        margin: auto;
        max-width: 80%;
    }

    .content {
        padding: 0rem 2rem;
        text-align: center;
    }

    .info {
        padding-right: 0;
    }

    .learn-btn {
        justify-content: center;
        margin-left: 0;
    }

}