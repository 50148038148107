.home-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    min-height: 55rem;
    max-height: 60rem;
}


.home-content {
    position: relative;
    z-index: 1;
    padding-top: 3rem;
}

.home-content h1 {
    font-size: 5.5rem;
    color: var(--color-light);
}

.home-content h5 {
    font-size: 2rem;
    color: var(--color-light);
}

.home-content p {
    font-size: 1.15rem;
    color: #c7c3c0;
    padding: 0 17rem;
}

.home-content .logo {
    max-width: 70rem;
    padding-top: 4rem;
}

.home-content .btn {
    --bs-btn-color: var(--color-light) !important;
    --bs-btn-border-color: var(--color-light) !important;
    --bs-btn-hover-color: var(--color-dark);
    --bs-btn-hover-bg: var(--color-light);
}

.home-content a {
    color: transparent;
}

@media screen and (max-width: 1400px) {
    .home-content h1 {
        font-size: 4.5rem;
    }

    .home-content p {
        font-size: 1.25rem;
        padding: 2rem 6rem;
    }
}

@media screen and (max-width: 1200px) {

    .home-content p {
        font-size: 1.25rem;
        padding: 2rem 6rem;
    }

    .home-content h5 {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 1020px) {
    .home-content h1 {
        font-size: 4.5rem;
    }

    .home-content p {
        font-size: 1rem;
    }

    .home-content h5 {
        font-size: 1.15rem;
    }

    .home-content .logo {
        padding-top: 8rem;
    }
    
}

@media screen and (max-width: 770px) {

    .home-content h1 {
        font-size: 3.125rem;
    }

    .home-content p {
        font-size: 0.8rem;
        padding: 2rem 5rem;
    }

    .home-content .btn {
        margin-top: 2rem;
    }
    
}