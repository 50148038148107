.footer {
    background-color: var(--color-dark);
}

.desktop-footer {
    padding-top: 6rem;
    padding-bottom: 3rem;
}

.footer .logo {
    width: 24rem !important;
    transform: translateX(-20px);
}

.pin {
    width: 3.5rem;
}

.copyright {
    padding-top: 3.25rem;
    font-size: x-small;
}

.footer p,
.footer h3,
.footer h4,
.footer h5 {
    color: var(--color-light);
}

.address {
    font-size: 1rem;
}

.socials {
    width: 2rem !important;
}

.mobile-footer {
    display: none !important;
}

.footer a {
        color: transparent;
    
}


@media screen and (max-width: 990px) {
    .desktop-footer {
        display: none !important;
    }

    .mobile-footer {
        padding-top: 5rem !important;
        padding-bottom: 2rem !important;
        display: block !important;
    }

    .copyright {
        padding-top: 2rem;
    }
}