.inventory-nav {
    padding-top: 8.5rem;
    z-index: 1;
    background-color: #D1C2B5;
    border-bottom: rgba(89, 75, 61, 0.3) solid 1px;
    box-shadow: 0px 8px 21.9px 0px rgba(55, 29, 34, 0.15);
}

.inventory-nav .nav {
    --bs-nav-link-color: var(--color-dark);
    --bs-nav-link-hover-color: ;
}

.dropdown-content {
    padding-top: 0.5rem;
}

.dropdown-content a, 
.dropdown-content p {
    padding-bottom: 0rem;
    padding-top: 0rem;
}

.nav-title{
    padding-right: 1.5rem;
}

.dropdown-menu {
    --bs-dropdown-bg: #D1C2B5;
    --bs-dropdown-link-hover-bg: #D1C2B5;
    --bs-dropdown-link-active-bg: var(--color-light);
}

.form-select {
    font-family: var(--font-family-secondary);
    background-color: var(--color-light);
    border-color: var(--color-gray);
    overflow-y: auto;

}


.dropdown-menu p:hover {
    scale: 1.01;
    font-weight: 600;
}

.dropdown-toggle::after {
    content: none;
}

.dropdown-toggle h5:hover {
    scale: 1.03;
    font-weight: bolder;
}

.btn {
    --bs-btn-padding-x: 1.5rem;
}

.inventory-nav .btn.show,
.inventory-nav .btn:hover,
.inventory-nav .btn:active,
.inventory-nav .btn:enabled {
  border: none;
}


.dropdown-item p {
    margin: 0.25rem 0rem;
}

.filter-dropdown {
    width: 20rem;
    max-height: 30rem;
    overflow-y: auto;
}

@media screen and (max-width:1000px) {
    .nav h5 {
        font-size: 1rem;
    }
    .btn {
        --bs-btn-padding-x: 1rem;
    }
}

@media screen and (max-width:770px) {
    .nav h5 {
        font-size: 0.9rem;
    }

    .btn {
        --bs-btn-padding-x: 0.5rem;
    }


    .inventory-cell-container {
        padding-top: 18rem;
    }

    .filter-dropdown {
        max-height: 20rem;
    }
}


