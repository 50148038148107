.press {
    padding-top: 10dvh;
    padding-bottom: 10dvh;
}

.press h2 {
    font-weight: var(--font-weight-bold);
}

@media screen and (max-width: 990px) {
    .press-img {
        padding: 2rem 10rem!important;
    }

    .bottom-row {

        padding-top: 0rem!important;
    }
}

@media screen and (max-width: 770px) {
    .press {
        padding-top: 10dvh;
    }
    .press-img {
        padding: 3rem 6rem!important;
    }

    .bottom-row {

        padding-top: 0rem!important;
    }
}